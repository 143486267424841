import { BackArrowIcon } from 'assets/Imports';
import React, { RefObject, useState, useEffect } from 'react'

interface GoUpButtonProps {
  position?: "top" | "bottom";
  containerRef: RefObject<HTMLElement>;
}

export const GoUpButton = ({ position, containerRef }: GoUpButtonProps) => {
  const [isAtTop, setIsAtTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        setIsAtTop(containerRef.current.scrollTop <= 0);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerRef]);

  const handleScroll = () => {
    if (containerRef.current) {
      if (isAtTop) {
        // Scroll hacia abajo
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: 'smooth'
        });
      } else {
        // Scroll hacia arriba
        containerRef.current.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  };

  return (
    <div
      className="go-up-btn"
      onClick={handleScroll}
	  style={{
		...(position === "top" ? { top: "9vh" } : {}),
		...(isAtTop ? { bottom: "9vh" } : { bottom: "2vh" }),
		zIndex: 100,
		cursor: 'pointer',
		position: 'fixed',
		display: 'flex',  // Agregamos esto para mejor control del contenido
		justifyContent: 'center',
		alignItems: 'center'
	
      }}
    >
      <img 
        src={BackArrowIcon} 
        alt="flecha icono" 
		style={{
			transform: isAtTop ? 'rotate(-91deg)' : 'rotate(91deg)',
			transition: 'transform 0.3s ease',
			transformOrigin: 'center center', // Especificamos el punto de origen
			width: '24px',  // O el tamaño que necesites
			height: '24px'  // O el tamaño que necesites
		  }}
      />
    </div>
  )
}