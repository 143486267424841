export enum TimePeriod {
	NONE = 'Selecciona una periodo',
	PREVIOUS_DAY = 'previous_day',
	LAST_MINUTES = 'last_minutes',
	PREVIOUS_WEEK = 'previous_week',
	PREVIOUS_MONTH = 'previous_month',
	CUSTOM = 'custom'
  }

  export enum MinuteIntervals {
  NONE = 0,
  FIVE = 5,
  TEN = 10,
  FIFTEEN = 15,
  TWENTY = 20,
  THIRTY = 30,
  FORTY_FIVE = 45,
  SIXTY = 60
}