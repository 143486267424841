import { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import "./material-styles.css";
import { addDoc, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "initFirebase";
import firebase from "firebase/compat/app";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../../../functions/DragDropFunctions";
import { getDate, romanize } from "functions/Functions";
import UsersViewsModal from "@components/Admin/UsersViewsModal";
import { AdminGenSelector } from "@components/Admin/AdminGenSelector/AdminGenSelector";
import { compareGenerations } from "functions/Generations/FunctionsGenerations";
import {
	BackArrowIcon,
	CloseCircleIcon,
	DocumentAttachFileIcon,
	DownArrow,
	ViewIcon,
} from "assets/Imports";
import Tooltip from "functions/Tooltip/Tooltip";
import { useAuth } from "@hooks/useAuth";
import LinksHistoryModal from "./components/LinksHistoryModal";
import useLinksHistory from "@hooks/LinkHistory/useLinksHistory";
import FilterGenerations from "@components/FilterGenerations/FilterGenerations";
import { EyeIcon } from "assets/Imports";
import moment from "moment";
import { useGenerationsAndMaterials } from "@hooks/GenerationsFilters/useGenerations";
import useGetMissingMaterial from "@hooks/CloudFunctions/UseGetMissingMaterial/useGetMissingMaterial";
import { GetMissingMaterialParams } from "interfaces/getMissingMaterialHook";
import { GoUpButton } from "@components/Buttons/GoUpButton";
function MaterialContainer({ user }) {
	const [genId, setGenId] = useState<string>("");
	const [gen, setGen]: any = useState(null);
	const [check, setCheck] = useState(false);
	const [materiales, setMateriales]: any = useState([]);
	const [materialModal, setMaterialModal] = useState(false);
	const [material, setMaterial]: any = useState(null);
	const [files, setFiles]: any = useState([]);
	const [loadingSave, setLoadingSave] = useState(false);
	const [notifyMaterials, setNotifyMaterials] = useState(false);
	const [notifyRecordings, setNotifyRecordings] = useState(false);
	const [addParts, setAddparts] = useState<boolean>(false);
	const [modalShow, setModalShow] = useState<boolean>(false);
	const [seeViews, setSeeViews] = useState<Array<string>>(new Array());
	const [showLinksHistory, setShowLinksHistory] = useState<boolean>(false);
	const { SaveLinksHistory, ManualLinksHistory } = useLinksHistory();
	const getMissingMaterial = useGetMissingMaterial();
	const [materialsAux, setmaterialAux] = useState([]);
	const User = useAuth().user;
	const containerRef = useRef<HTMLDivElement>(null);

	const {
		gens,
		handleSelect,
		handleCheck,
		filterEs,
		getGenerations,
		getSeminaries,
		eyeColor,
		load,
		setEyeColor,
	} = useGenerationsAndMaterials(compareGenerations, user, compare2, compare3);

	useEffect(() => {
		getGenerations();
	}, []);

	function compare2(a, b) {
		if (a.num > b.num) {
			return -1;
		}
		if (a.num < b.num) {
			return 1;
		}
		return 0;
	}
	function compare3(a, b) {
		if (a.num < b.num) {
			return -1;
		}
		if (a.num > b.num) {
			return 1;
		}
		return 0;
	}

	const checkGen = (gen) => {
		//console.log("gen container", gen);
		setGenId(gen.id);
		setGen(gen);
		setMateriales(gen.seminarios);
		setCheck(true);
	};

	const changeGen = (genId) => {
		let index = gens.map((object) => object.id).indexOf(genId);
		//console.log(index, "gens", gens);
		setGen(gens[index]);
		setMateriales(gens[index]?.seminarios);
		setCheck(true);
	};

	const goBack = () => {
		setMateriales([]);
		setGen(null);
		getGenerations();
		setCheck(false);
	};

	const handleMaterialModal = (material) => {
		setMaterialModal(true);
		setMaterial(material);
		setNotifyMaterials(material.notifyMaterials === undefined ? false : material.notifyMaterials);
		setNotifyRecordings(
			material.notifyRecordings === undefined ? false : material.notifyRecordings
		);
		setFiles(material.files);
	};

	const handleChangeFile = (e) => {
		let filesD = e.target.files;
		setFiles([...files, ...Array.from(filesD)]);
	};

	const materialModalClose = () => {
		setMaterialModal(false);
		setLoadingSave(false);
		setFiles([]);
		setNotifyMaterials(false);
		setNotifyRecordings(false);
		getSeminaries(gens);
	};

	const gensRef = useRef(gens); // Inicializamos la referencia con el valor actual de gens
	useEffect(() => {
		gensRef.current = gens; // Actualizamos la referencia cuando gens cambia
	}, [gens]);

	useEffect(() => {
		if (gen !== null) {
			console.log("genss useEffect", gensRef.current);
			const index = gensRef.current.findIndex((object) => {
				return object?.id === gen?.id;
			});
			setGen(gensRef.current[index]);
			setMateriales(gensRef.current[index]?.seminarios);
		}
	}, [gensRef, gen]);

	const deleteFile = (index) => {
		let filesArray = files;
		filesArray.splice(index, 1);
		setFiles([...Array.from(filesArray)]);
	};

	const saveMaterial = async () => {
		setLoadingSave(true);

		let finalArrayFiles: any = [];

		if (files.length > 0) {
			for (let index = 0; index < files.length; index++) {
				if (files[index].linkFile === undefined) {
					const upload = await firebase
						.storage()
						.ref(`materiales/${uuidv4()}/${files[index].name}`)
						.put(files[index]);
					await upload.ref.getDownloadURL().then(async (res) => {
						const data = {
							name: files[index].name,
							linkFile: res,
						};
						finalArrayFiles.push(data);
					});
				} else {
					finalArrayFiles.push(files[index]);
				}
			}
		}

		const materialRef = doc(db, "generations", gen.id, "material", material.id);
		let updatedLinksHistory: any = [];
		try {
			let videoParts: any = ["videoUrl", "videoUrl12", "videoUrl13", "videoUrl2"];
			let newLinks: any = [
				material.videoUrl,
				material.videoUrl12,
				material.videoUrl13,
				material.videoUrl2,
			];
			updatedLinksHistory = await ManualLinksHistory({
				db: db,
				genId: gen.id,
				seminaryId: material.id,
				videoPart: videoParts,
				newLink: newLinks,
			});
		} catch (error) {
			console.error("Error al guardar la historia de los enlaces", error);
		}

		await updateDoc(materialRef, {
			...material,
			...(updatedLinksHistory.length ? { Historylinks: updatedLinksHistory } : {}),
			files: finalArrayFiles,
			notifyMaterials: notifyMaterials,
			notifyRecordings: notifyRecordings,
		}).then(async () => {
			const q = query(collection(db, "generations"), where("genCopyId", "==", gen.id));
			const snap = await getDocs(q);

			if (snap.size > 0) {
				await Promise.all(
					snap.docs.map(async (genCopy) => {
						const genCopyRef = query(
							collection(db, "generations", genCopy.id, "material"),
							where("num", "==", material.num)
						);
						const materialCopySnap = await getDocs(genCopyRef);

						if (materialCopySnap.size > 0) {
							const materialCopyRef = doc(
								db,
								"generations",
								genCopy.id,
								"material",
								materialCopySnap.docs[0].id
							);

							const data = { ...material };

							delete data.date;
							await updateDoc(materialCopyRef, {
								...data,
								files: finalArrayFiles,
							});
						}
					})
				);
			}
		});

		if (
			(material.notifyMaterials !== undefined && material.notifyMaterials === false) ||
			(material.notifyRecordings !== undefined && material.notifyRecordings === false) ||
			(material.notifyMaterials === undefined && material.notifyRecordings === undefined)
		) {
			const q2 = query(collection(db, "users"), where("generationId", "==", gen.id));
			const querySnapshot2 = await getDocs(q2);

			let index = 0;
			querySnapshot2.forEach(async (user) => {
				if (
					notifyMaterials &&
					(material.notifyMaterials === false || material.notifyMaterials === undefined)
				) {
					await addDoc(collection(db, "notifications"), {
						name: "Academia de Lenguas Internacionales",
						text: "Ya están disponibles los materiales del Seminario " + romanize(material.num),
						date: new Date(),
						userId: user.id,
						materialId: material.id,
						userType: 2,
						seen: false,
						profileColor: "#000000",
						type: "materialReady",
						seminaryNum: material.num,
					});
				}
				if (
					notifyRecordings &&
					(material.notifyRecordings === false || material.notifyRecordings === undefined)
				) {
					await addDoc(collection(db, "notifications"), {
						name: "Academia de Lenguas Internacionales",
						text: "Ya está disponible la grabación del Seminario " + romanize(material.num),
						date: new Date(),
						userId: user.id,
						materialId: material.id,
						userType: 2,
						seen: false,
						profileColor: "#000000",
						type: "recordingsReady",
						seminaryNum: material.num,
					});
				}
			});

			getMissingMaterial({ userId: user.id, generationId: gen.id, materialId: material.id });

			materialModalClose();
			setLoadingSave(false);
		} else {
			materialModalClose();
			setLoadingSave(false);
		}
	};

	function getTitle(name) {
		let splitName = name.split(" - ");

		return splitName[0];
	}

	function getSubtitle(name) {
		let splitName = name.split(" - ");
		let subtitle = "";

		for (let index = 1; index < splitName.length; index++) {
			subtitle = subtitle + splitName[index] + (index < splitName.length - 1 ? " - " : "");
		}
		return subtitle;
	}

	const onDragEnd = ({ destination, source }) => {
		// dropped outside the list
		if (!destination) return;

		const newItems = reorder(files, source.index, destination.index);

		// Create a new array here!
		// const newItemSequence = newItems.map(
		//     (el: any, index) => (el = { ...el })
		// );

		setFiles(newItems);
	};

	const handleSeeViews = (views) => {
		setSeeViews(views);
		setModalShow(true);
	};

	useEffect(() => {
		if (genId.length > 0) {
			changeGen(genId);
		}
	}, [genId]);

	useEffect(() => {
		if (check && gen !== null) {
			if (gen.nextSeminaries.length > 0) {
				let scroll = window.document.getElementById("material-" + gen.nextSeminaries[0].num);

				if (scroll !== null) {
					scroll.setAttribute("style", "border:1px solid #ca5050");
					scroll.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
				}
			}
		}
	}, [check]);

	useEffect(() => {
		console.log(filterEs);
	}, [filterEs]);

	return (
		<>
			{check === false ? (
				<>
					<h1>Materiales</h1>
					<div className="generations-subtitle-container materials-subtitle-container">
						<FilterGenerations
							EyeIcon={EyeIcon}
							eyeColor={eyeColor}
							filterEs={filterEs}
							handleSelect={handleSelect}
						/>
					</div>
					{gens.length > 0 && (
						<>
							{gens.map((gen, index) => {
								return (
									<>
										<div className="generations-card" key={"material-generations-card-" + gen.id}>
											<div className="generations-card-left w-100 text-center">
												<h2>{getTitle(gen.name)}</h2>
												<h2 style={{ color: "#000000" }}>{getSubtitle(gen.name)}</h2>
											</div>
											<div className="generations-card-right">
												{gen.nextSeminaries && gen.nextSeminaries[0] !== undefined && (
													<div className="files-next-seminary-container">
														<p>
															<b>{romanize(gen.nextSeminaries[0].num)}</b>
														</p>
														<p>{gen.nextSeminaries[0].files.length}/4</p>
													</div>
												)}
												<button onClick={() => checkGen(gen)}>Revisar</button>
											</div>
										</div>
									</>
								);
							})}
						</>
					)}
				</>
			) : (
				<>
					<GoUpButton position="top" containerRef={containerRef} />
					{gen !== null && (
						<div className="gen-name-container">
							<button onClick={() => goBack()} className="goback-btn">
								{"Atrás"}
							</button>
							<AdminGenSelector
								setGenId={setGenId}
								genId={genId}
								load={load}
								handleCheck={handleCheck}
								gens={gens}
								gen={gen}
								eyeColor={eyeColor}
								setEyeColor={setEyeColor}
							/>
						</div>
					)}
					<div ref={containerRef} className="materials-list-container">
						{" "}
						{/* Aquí va el ref */}
						{materiales?.length > 0 && !loadingSave && (
							<>
								{materiales.map((material, index) => {
									return (
										<>
											<div
												className="generations-card"
												id={"material-" + material.num}
												key={"material-card-" + material.id}
											>
												<div className="generations-card-left">
													<h2>Seminario {material.num}</h2>
													<p>{getDate(material.date)}</p>
													{material.files.length > 0 ? (
														<p>Contenido incluido</p>
													) : (
														<p>Sin contenido incluido</p>
													)}
												</div>
												<div className="generations-card-right">
													<button onClick={() => handleMaterialModal(material)}>Revisar</button>
												</div>
											</div>
										</>
									);
								})}
							</>
						)}
					</div>
					{material !== null && (
						<>
							<LinksHistoryModal
								show={showLinksHistory}
								onHide={() => setShowLinksHistory(false)}
								db={db}
								genId={gen.id}
								materialId={material.id}
							/>

							{material.usersViews !== undefined && (
								<UsersViewsModal
									show={modalShow}
									onHide={() => setModalShow(false)}
									usersIds={seeViews}
								/>
							)}
							<Modal
								show={materialModal}
								onHide={materialModalClose}
								dialogClassName="student-modal"
								aria-labelledby="example-custom-modal-styling-title"
							>
								<Modal.Header closeButton>
									<Modal.Title id="example-custom-modal-styling-title">Detalles</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<div className="views-recordings-container">
										<Tooltip
											content={`Número de estudiantes\n que han visto la grabación`}
											direction="bottom"
										>
											<div className="recordings-views-container bg-success total-views">
												<img src={ViewIcon} alt="icono de ojo" />
												<p>{material.totalViews !== undefined ? material.totalViews : 0}</p>
											</div>
										</Tooltip>
										<Tooltip content={`Total de\n visualizaciones`} direction="bottom">
											<div
												className="recordings-views-container"
												onClick={() => handleSeeViews(material.usersViews)}
											>
												<img src={ViewIcon} alt="icono de ojo" />
												<p>{material.usersViews !== undefined ? material.usersViews.length : 0}</p>
											</div>
										</Tooltip>
									</div>
									<h2 style={{ fontSize: "1.5rem" }}>Seminario {material.num}</h2>
									<button
										className="action-btn secondary-btn-bg mb-2"
										onClick={() => setShowLinksHistory(true)}
									>
										Ver links anteriores
									</button>
									<label className="mb-2 regular-text-header" htmlFor="student-name">
										Enlace de grabación <span style={{ color: "#Adadad" }}>(interno)</span>:{" "}
									</label>

									<div className="input-new-gen-container">
										<input
											className="input-new-gen"
											name="student-name"
											value={material.videoUrl}
											id="student-name"
											type="text"
											onChange={(e) => {
												setMaterial({ ...material, videoUrl: e.target.value });
											}}
											placeholder="Ingrese la liga"
										/>
									</div>
									<div className={"add-parts-container " + (addParts ? "active" : "")}>
										<div className="add-parts-btn" onClick={() => setAddparts(!addParts)}>
											<h3>Añadir más partes</h3>
											<img src={DownArrow} alt="flecha hacia abajo" />
										</div>
										<div className="views-recordings-container">
											<div className="recordings-views-container bg-success total-views">
												<img src={ViewIcon} alt="icono de ojo" />
												<p>{material.totalViews2 !== undefined ? material.totalViews2 : 0}</p>
											</div>
											<div
												className="recordings-views-container"
												onClick={() => handleSeeViews(material.usersViews2)}
											>
												<img src={ViewIcon} alt="icono de ojo" />
												<p>
													{material.usersViews2 !== undefined ? material.usersViews2.length : 0}
												</p>
											</div>
										</div>
										<label className="regular-text-header" htmlFor="student-name">
											Enlace de grabación (parte 2 opcional):{" "}
										</label>
										<div className="input-new-gen-container">
											<input
												className="input-new-gen"
												name="student-name"
												value={material.videoUrl12}
												id="student-name"
												type="text"
												onChange={(e) => {
													setMaterial({ ...material, videoUrl12: e.target.value });
												}}
												placeholder="Ingrese la liga"
											/>
										</div>
										<div className="views-recordings-container">
											<div className="recordings-views-container bg-success total-views">
												<img src={ViewIcon} alt="icono de ojo" />
												<p>{material.totalViews3 !== undefined ? material.totalViews3 : 0}</p>
											</div>
											<div
												className="recordings-views-container"
												onClick={() => handleSeeViews(material.usersViews3)}
											>
												<img src={ViewIcon} alt="icono de ojo" />
												<p>
													{material.usersViews3 !== undefined ? material.usersViews3.length : 0}
												</p>
											</div>
										</div>
										<label className="regular-text-header" htmlFor="student-name">
											Enlace de grabación (parte 3 opcional):{" "}
										</label>
										<div className="input-new-gen-container">
											<input
												className="input-new-gen"
												name="student-name"
												value={material.videoUrl13}
												id="student-name"
												type="text"
												onChange={(e) => {
													setMaterial({ ...material, videoUrl13: e.target.value });
												}}
												placeholder="Ingrese la liga"
											/>
										</div>
									</div>
									<label className="regular-text-header" htmlFor="student-name">
										Enlace de YouTube:{" "}
									</label>
									<div className="input-new-gen-container">
										<input
											className="input-new-gen"
											name="student-name"
											value={material.videoUrl2}
											id="student-name"
											type="text"
											onChange={(e) => {
												setMaterial({ ...material, videoUrl2: e.target.value });
											}}
											placeholder="Ingrese link de YouTube"
										/>
									</div>
									<label className="regular-text-header" htmlFor="student-name">
										Notas:{" "}
									</label>
									<div className="input-new-gen-container">
										<textarea
											className="w-100 border-0"
											value={material.notes}
											placeholder="Notas"
											onChange={(e) => setMaterial({ ...material, notes: e.target.value })}
											name="notes"
											id="notes"
											cols={30}
											rows={6}
										></textarea>
									</div>
									<label className="regular-text-header" htmlFor="student-name">
										Archivos:{" "}
									</label>
									{files.length > 0 ? (
										<>
											<DragDropContext onDragEnd={onDragEnd}>
												<Droppable droppableId="droppable-list">
													{(provided) => (
														<div
															ref={provided.innerRef}
															{...provided.droppableProps}
															style={{ height: 108 * files.length }}
														>
															{files.map((file, index) => {
																return (
																	<Draggable
																		draggableId={`file-` + index}
																		index={index}
																		key={"file-item-" + index}
																	>
																		{(provided, snapshot) => (
																			<div
																				ref={provided.innerRef}
																				{...provided.draggableProps}
																				{...provided.dragHandleProps}
																				className={
																					"material-card " + (snapshot.isDragging ? "dragging" : "")
																				}
																			>
																				{file.linkFile !== undefined ? (
																					<>
																						<a
																							style={{ textDecoration: "none", color: "black" }}
																							target="_blank"
																							href={file.linkFile}
																						>
																							<div>{file.name}</div>
																						</a>
																						<img
																							className="delete-icon-btn"
																							onClick={() => deleteFile(index)}
																							src={CloseCircleIcon}
																							alt="close-circle-icon"
																						/>
																					</>
																				) : (
																					<>
																						{file.name}{" "}
																						<img
																							className="delete-icon-btn"
																							onClick={() => deleteFile(index)}
																							src={CloseCircleIcon}
																							alt="close-circle-icon"
																						/>
																					</>
																				)}
																			</div>
																		)}
																	</Draggable>
																);
															})}
														</div>
													)}
												</Droppable>
											</DragDropContext>
										</>
									) : (
										<div className="no-material-card">No hay material para esta sesión.</div>
									)}

									<div className="dropfiles-materiales mb-2">
										<label htmlFor="file">
											<img src={DocumentAttachFileIcon} alt="document-attach-file-icon" />
											<p>Arrastra y suelta el archivo de los materiales para esta sesión</p>
										</label>
										<input
											id="file"
											name="file"
											type="file"
											onChange={(e) => handleChangeFile(e)}
											multiple
										/>
									</div>
									<div>
										<label htmlFor="notificar" className="mr-2">
											Notificar a los alumnos del material
										</label>
										<input
											className="mb-0"
											type="checkbox"
											id="notificar"
											checked={notifyMaterials}
											onChange={(e) => setNotifyMaterials(e.target.checked)}
											disabled={
												material.notifyMaterials !== undefined && material.notifyMaterials === true
											}
										/>
									</div>
									<div>
										<label htmlFor="notificar2" className="mr-2">
											Notificar a los alumnos de la grabación
										</label>
										<input
											className="mb-0"
											type="checkbox"
											id="notificar2"
											checked={notifyRecordings}
											onChange={(e) => setNotifyRecordings(e.target.checked)}
											disabled={
												material.notifyRecordings !== undefined &&
												material.notifyRecordings === true
											}
										/>
									</div>
									<div style={{ display: "flex", justifyContent: "center" }}>
										{!loadingSave ? (
											<button className="save-materials-btn" onClick={saveMaterial}>
												Guardar
											</button>
										) : (
											<div className="spinner-border text-danger" role="status">
												<span className="sr-only">Loading...</span>
											</div>
										)}
									</div>
								</Modal.Body>
							</Modal>
						</>
					)}
				</>
			)}
		</>
	);
}

export default MaterialContainer;
