import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { capitalizeFirstLetter, returnFlagByLanguage, romanize } from "functions/Functions";
import { language, lastWordsObject } from "interfaces/Interfaces";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { db } from "initFirebase";
import "./PersonalizedTestStyles.scss";
import {
	getCategoriesByArray,
	getDifficultNumber,
	getDynamicQuizName,
	getLangSpanish,
	getLangSpanishSimple,
} from "./functions/functions";
import { getSegmentsArray } from "@containers/VocabularySeminaryContainer/functions/VocabularyFunctions";
import SeeWordsModal from "../../../containers/VocabularySeminaryContainer/components/SeeWordsModal/SeeWordsModal";
import { CategoryCard } from "./components/CategoryCard";
import AdvanceConfigQuiz from "@components/Vocabulary/AdvanceConfigQuiz.tsx/AdvanceConfigQuiz";
import { getUniqueTotalWordsArray } from "@containers/VocabularySeminaryContainer/components/Test/functions/TestFunctions";
import { defaultQuiz } from "@containers/Admin/VocabularyQuizContainer/components/DefaultQuizzesMain/DefaultQuizzesMain";
import { Dropdown, Form } from "react-bootstrap";
import { Progress, Tooltip } from "antd";
import { languagesEnglish } from "functions/DefaultValues";
import {
	AddCircle,
	BrazilFlagSVG,
	ChinaFlagSVG,
	CreateIcon,
	EasyDifficult,
	Expert,
	Fecha,
	FranceFlagSVG,
	GermanyFlagSVG,
	HardDifficult,
	ItalyFlagSVG,
	JapanFlagSVG,
	ListIconVocabulary,
	NormalDifficult,
	RemoveCircle,
	RussiaFlagSVG,
	UnitedStatesFlagSVG,
	VeryEasy,
} from "assets/Imports";
import { CustomSelect } from "./components/CustomSelect";
import { MinuteIntervals, TimePeriod } from "./common/enums/studyPeriods.enum";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import { getWordStudiedPerPeriod } from "@services/VocabularyService";
import { StudyWordsFilters } from "interfaces/Vocabulary/VocabularyInterfaces";
import { GoUpButton } from "@components/Buttons/GoUpButton";
export interface langObjectArray {
	langSelected?: any;
	langArray?: Array<language>;
	langArrayBase?: Array<language>;
}

interface categories {
	selectedCategories: Array<string>;
	avaibleCategories: Array<string>;
	selectedSubCategories: Array<string>;
	avaibleSubCategories: Array<subcategories>;
}

interface subcategories {
	parents: Array<string>;
	subCategory: string;
}

interface seeWordsModal {
	show: boolean;
	wordsArray: Array<language>;
	selected: string;
}
interface studyDateRange{
	startDate: string;
	endDate: string;
}
//	THIS COMPONENT IS TO CREATE QUIZZES, IN THE USER SIDE IT CREATES A PERSONALIZED QUIZ FOR A USER
//	IN ADMIN SIDE IT CREATES A DEFAULT QUIZ FOR USERS
function PersonalizedTest(props) {
	const editQuiz: defaultQuiz = props.editQuiz;
	const [testView, setTestView] = useState<number>(1);
	const [subView, setSubView] = useState<number>(2);
	const [wordsNum, setWordsNum] = useState<number>(0);
	const [langSelected, setSelectedLang] = useState<langObjectArray>({ langSelected: "none" });
	const [categoriesSelection, setCategoriesSelection] = useState<categories>({
		avaibleCategories: props.categories,
		selectedCategories: new Array(),
		selectedSubCategories: new Array(),
		avaibleSubCategories: props.subCategories,
	});
	const [selectedDifficult, setSelectedDifficult] = useState<Array<string>>(new Array());
	const [lastOptions, setLastOptions] = useState<lastWordsObject>({
		newWords: false,
		toLearn: false,
		dominated: false,
		period: false, // Agregamos el estado para el periodo
		answerType: "normal",
	});
	const [name, setName] = useState<string>("Ronda personalizada");
	const [seeWordsModal, setSeeWordsModal] = useState<seeWordsModal>({
		show: false,
		wordsArray: new Array(),
		selected: "",
	});
	
	const [studyDateRange,setStudyDateRange] = useState<studyDateRange>(
		{
			endDate: "",
			startDate: "",
		}
	);

	const [creatingLoader, setCreatingLoader] = useState<boolean>(false);
	const [categoriesBackUp, setCategoriesBackUp] = useState<Array<string>>(new Array());
	const [userEditName, setUserEditName] = useState<boolean>(false);
	const [showCustomDifficulty, setShowCustomDifficulty] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [period, setPeriod] = useState<TimePeriod>(TimePeriod.NONE);
	const [minutes, setMinutes] = useState<MinuteIntervals>(MinuteIntervals.NONE);
	const [dateRange, setDateRange] = useState<DateRange>([
		{
			startDate: null,
			endDate: null,
			key: "selection",
		},
	]);
	// Dentro de la definición de tu componente, junto a los demás estados:
	const [periodWordIds, setPeriodWordIds] = useState<number[]>([]);
	const containerRef = useRef<HTMLDivElement>(null);

	const handleSelect = (ranges) => {
		setDateRange([ranges.selection]);
	};

	const startDate = dateRange[0]?.startDate ? new Date(dateRange[0].startDate) : null;
	const endDate = dateRange[0]?.endDate ? addDays(new Date(dateRange[0].endDate), 1) : null;

	if (endDate) {
		endDate.setHours(0, 0, 0, 0);
	}

	const handleSeeWordsModal = (category, type: string, selectedCategories?) => {
		if (type === "category") {
			setSeeWordsModal({
				show: true,
				wordsArray: langSelected.langArrayBase!.filter((e) => e.category.includes(category)),
				selected: category,
			});
		} else {
			setSeeWordsModal({
				show: true,
				wordsArray: langSelected.langArrayBase!.filter(
					(e) =>
						e.category.some((c) => selectedCategories.includes(c)) &&
						e.sub_category.includes(category)
				),
				selected: category,
			});
		}
	};

	const onChangeLangSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			setSelectedLang({ langSelected: e.target.name });
		} else {
			setSelectedLang({ langSelected: "none" });
		}
	};

	const onChangeCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
		let categoriesArray = new Array(...categoriesSelection.selectedCategories);
		let strCategory = e.target.name;
		if (!categoriesArray.includes(strCategory) && e.target.checked) {
			categoriesArray.push(strCategory);
			setCategoriesSelection({
				...categoriesSelection,
				selectedCategories: [...categoriesArray],
				selectedSubCategories: [],
			});
		} else if (categoriesArray.includes(strCategory) && !e.target.checked) {
			let index = categoriesArray.findIndex((s) => s === strCategory);
			categoriesArray.splice(index, 1);
			setCategoriesSelection({
				...categoriesSelection,
				selectedCategories: [...categoriesArray],
				selectedSubCategories: [],
			});
		}
	};

	const onChangeSubCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
		let subCategoriesArray = new Array(...categoriesSelection.selectedSubCategories);
		let strCategory = e.target.name;
		if (!subCategoriesArray.includes(strCategory) && e.target.checked) {
			subCategoriesArray.push(strCategory);
			setCategoriesSelection({
				...categoriesSelection,
				selectedSubCategories: [...subCategoriesArray],
			});
		} else if (subCategoriesArray.includes(strCategory) && !e.target.checked) {
			let index = subCategoriesArray.findIndex((s) => s === strCategory);
			subCategoriesArray.splice(index, 1);
			setCategoriesSelection({
				...categoriesSelection,
				selectedSubCategories: [...subCategoriesArray],
			});
		}
	};

	const onChangeDifficult = (e: React.ChangeEvent<HTMLInputElement>) => {
		let difficultArray = new Array(...selectedDifficult);
		let strDifficult = e.target.name;

		if (!difficultArray.includes(strDifficult) && e.target.checked) {
			difficultArray.push(strDifficult);
			setSelectedDifficult(difficultArray);
		} else if (difficultArray.includes(strDifficult) && !e.target.checked) {
			let index = difficultArray.findIndex((d) => d === strDifficult);
			difficultArray.splice(index, 1);
			setSelectedDifficult(difficultArray);
		}
	};

	const onChangeNameQuiz = (e) => {
		setName(e.target.value);
		if (!userEditName) {
			setUserEditName(true);
		}
	};

	const testPreview = () => {
		setWordsNum(langSelected.langArray !== undefined ? langSelected.langArray.length : 0);
		setTestView(2);
	};

	//	THIS FUNCTION CREATES A PERSONALIZED QUIZ FOR A USER
	const createTest = async () => {
		setLoading(true);
		let finalArrayWords = JSON.parse(
			JSON.stringify(langSelected.langArray!.sort((a, b) => 0.5 - Math.random()))
		);
		finalArrayWords.sort((a, b) => 0.5 - Math.random());
		finalArrayWords.splice(0, finalArrayWords.length - wordsNum);

		finalArrayWords.forEach((word) => {
			word.dominated = null;
			word.timesSeen = 1;
		});

		const segments = await getSegmentsArray(finalArrayWords);
		let qName = await getDynamicQuizName(segments);

		const docRef = await addDoc(collection(db, "quiz"), {
			userId: props.user.id,
			seminaryNum: parseInt(props.num),
			quizWords: segments,
			answerType: lastOptions.answerType,
			finished: false,
			createdAt: new Date(),
			finishedAt: null,
			language: langSelected.langSelected,
			difficult: selectedDifficult,
			correctWords: new Array(),
			incorrectWords: new Array(),
			index: 0,
			actualSegment: 0,
			name: name !== "Ronda personalizada" && name.length > 0 ? name : qName,
			errorsCounter: 0,
			acerteClicks: 0,
		});

		props.setTestId(docRef.id);
		props.setView(3);
	};

	//	THIS FUNCTION IS FOR ADMIN USE ONLY, IT CREATES A DEFAULT QUIZ
	const createDefaultQuiz = async () => {
		setCreatingLoader(true);
		let finalArrayWords = JSON.parse(
			JSON.stringify(langSelected.langArray!.sort((a, b) => 0.5 - Math.random()))
		);
		finalArrayWords.sort((a, b) => 0.5 - Math.random());

		finalArrayWords.forEach((word) => {
			word.dominated = null;
			word.timesSeen = 1;
		});

		const segments = await getSegmentsArray(finalArrayWords);

		const finalDefaultQuiz = {
			answerType: lastOptions.answerType,
			quizWords: segments,
			language: langSelected.langSelected,
			version: 1,
			name: name.length > 0 ? name : "Ronda predeterminada",
			seminary: parseInt(props.num),
		};

		try {
			if (editQuiz === undefined) {
				const quizRef = await addDoc(collection(db, "defaultQuizzes"), {
					...finalDefaultQuiz,
				}).then(() => {
					setCreatingLoader(false);
					props.setView(1);
				});
			} else {
				const docRef = doc(db, "defaultQuizzes", editQuiz.id || editQuiz.uuid);
				await updateDoc(docRef, {
					answerType: lastOptions.answerType,
					quizWords: segments,
					language: langSelected.langSelected,
					version: editQuiz.version + 1,
					name: name.length > 0 ? name : "Ronda predeterminada",
				}).then(() => {
					props.setView(1);
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const changeWordsNum = (e) => {
		let number: number = parseInt(e.target.value);

		if (number <= langSelected.langArray!.length && number >= 1) {
			setWordsNum(number);
		} else {
			if (isNaN(number)) {
				setWordsNum(NaN);
			}
		}
	};

	const handleCategorySelector = (e, word: language) => {
		let tempArray = JSON.parse(JSON.stringify(langSelected.langArray));
		if (!e.target.checked) {
			const index = tempArray!.findIndex((e) => e.id === word.id);
			tempArray!.splice(index, 1);
			setSelectedLang({ ...langSelected, langArray: tempArray });
		} else {
			tempArray!.push(word);
			setSelectedLang({ ...langSelected, langArray: tempArray });
		}
	};

	const handleSelectAll = (e, category) => {
		let tempArray = JSON.parse(JSON.stringify(langSelected.langArray));
		let catTempArray = JSON.parse(JSON.stringify(langSelected.langArrayBase!)).filter((e) =>
			e.category.includes(category)
		);
		let deleteIndexes = new Array();

		if (!e.target.checked) {
			tempArray?.map((word, index) => {
				if (word.category.includes(category)) {
					deleteIndexes.push(word.id);
				}
			});

			deleteIndexes.forEach((i) => {
				tempArray?.splice(
					tempArray.findIndex((e) => e.id === i),
					1
				);
			});
		} else {
			catTempArray.map((word, index) => {
				if (tempArray?.findIndex((e) => e.id === word.id) === -1) {
					tempArray.splice(tempArray.length, 0, word);
				}
			});
		}

		setSelectedLang({ ...langSelected, langArray: tempArray });
	};
	const toggleSelectAll = (selectAll) => {
		const langArray = selectAll ? props.words[langSelected.langSelected] : [];
		setSelectedLang({ ...langSelected, langArray: langArray });
		setLastOptions({
			...lastOptions,
			dominated: false,
			newWords: false,
			period: false,
			toLearn: false,
		});
	};

	useEffect(() => {
		if (
			langSelected.langSelected !== "none" &&
			langSelected.langArray === undefined &&
			categoriesSelection.selectedCategories.length === 0 &&
			categoriesSelection.selectedSubCategories.length === 0
		) {
			switch (langSelected.langSelected) {
				case "french":
					setSelectedLang({
						...langSelected,
						langArray: props.words.french,
						langArrayBase: props.words.french,
					});
					break;
				case "german":
					setSelectedLang({
						...langSelected,
						langArray: props.words.german,
						langArrayBase: props.words.german,
					});
					break;
				case "japanese":
					setSelectedLang({
						...langSelected,
						langArray: props.words.japanese,
						langArrayBase: props.words.japanese,
					});
					break;
				case "chinese":
					setSelectedLang({
						...langSelected,
						langArray: props.words.chinese,
						langArrayBase: props.words.chinese,
					});
					setLastOptions({ ...lastOptions, answerType: "kanji" });
					break;
				case "russian":
					setSelectedLang({
						...langSelected,
						langArray: props.words.russian,
						langArrayBase: props.words.russian,
					});
					break;
				case "portuguese":
					setSelectedLang({
						...langSelected,
						langArray: props.words.portuguese,
						langArrayBase: props.words.portuguese,
					});
					break;
				case "italian":
					setSelectedLang({
						...langSelected,
						langArray: props.words.italian,
						langArrayBase: props.words.italian,
					});
					break;
				case "english":
					setSelectedLang({
						...langSelected,
						langArray: props.words.english,
						langArrayBase: props.words.english,
					});
					break;
			}
		} else if (
			langSelected.langSelected !== "none" &&
			langSelected.langArray !== undefined &&
			categoriesSelection.selectedCategories.length === 0
		) {
			let categoryArray = new Array();
			let index = 0;
			(subView === 1 ? langSelected.langArray : langSelected.langArrayBase)?.forEach((word) => {
				index++;
				word.category.forEach((category) => {
					if (!categoryArray.includes(category) && category !== "") {
						categoryArray.push(category);
					}
				});

				if (index === langSelected.langArray?.length) {
					setCategoriesSelection({
						...categoriesSelection,
						avaibleCategories: categoryArray,
						avaibleSubCategories: new Array(),
					});
				}
			});
		} else if (
			categoriesSelection.selectedCategories.length > 0 &&
			langSelected.langSelected !== "none"
		) {
			if (categoriesSelection.selectedCategories.length > 0) {
				let subCategoryArray =
					categoriesSelection.selectedSubCategories.length === 0
						? new Array()
						: new Array(...categoriesSelection.avaibleSubCategories);
				let index = 0;
				langSelected.langArray?.forEach((word) => {
					index++;
					word.sub_category.forEach((subCategory) => {
						if (
							!subCategoryArray.some((e) => e.subCategory === subCategory) &&
							subCategory !== null
						) {
							let parents = new Array();
							word.category.map((c) => {
								if (!parents.includes(c) && categoriesSelection.selectedCategories.includes(c)) {
									parents.push(c);
								}
							});
							subCategoryArray.push({ parents: parents, subCategory: subCategory });
						} else {
							let index = subCategoryArray.map((e) => e.subCategory).indexOf(subCategory);
							if (index !== -1) {
								let parents = subCategoryArray[index].parents;

								word.category.map((e) => {
									if (!parents.includes(e) && categoriesSelection.selectedCategories.includes(e)) {
										parents.push(e);
									}
								});

								subCategoryArray[index].parents = parents;
							}
						}
					});

					if (index === langSelected.langArray?.length) {
						setCategoriesSelection({
							...categoriesSelection,
							avaibleSubCategories: subCategoryArray,
						});
					}
				});
			} else {
				setCategoriesSelection({ ...categoriesSelection, avaibleSubCategories: new Array() });
			}
		} else if (langSelected.langSelected === "none") {
			setCategoriesSelection({
				...categoriesSelection,
				avaibleCategories: props.categories,
				avaibleSubCategories: new Array(),
			});
			if (categoriesSelection.selectedCategories.length > 0) {
				setCategoriesSelection({
					...categoriesSelection,
					selectedCategories: new Array(),
					selectedSubCategories: new Array(),
				});
			}
			if (selectedDifficult.length > 0) {
				setSelectedDifficult(new Array());
			}
			if (editQuiz !== undefined) {
				setSelectedLang({
					langSelected: editQuiz.language,
					langArray: getUniqueTotalWordsArray(editQuiz.quizWords),
					langArrayBase: props.words[editQuiz.language],
				});
				setName(editQuiz.name);
				setLastOptions({ ...lastOptions, answerType: editQuiz.answerType });
			} else {
				setLastOptions({
					...lastOptions,
					answerType: "selected-lang",
					dominated: false,
					newWords: false,
					period: false,
					toLearn: false,
				});
			}
		}
	}, [langSelected]);

	useEffect(() => {
		try {
			if (langSelected.langSelected !== "none" && langSelected.langArray !== undefined) {
				let langArray: Array<language> = JSON.parse(JSON.stringify(props.allWordsArray)).filter(
					(e) => {
						if (categoriesSelection.selectedCategories.length > 0) {
							for (let index = 0; index < categoriesSelection.selectedCategories.length; index++) {
								if (
									e.category.includes(categoriesSelection.selectedCategories[index]) &&
									e.language === langSelected.langSelected
								) {
									if (selectedDifficult.length === 0) {
										return true;
									} else {
										for (let index2 = 0; index2 < selectedDifficult.length; index2++) {
											if (
												getDifficultNumber(
													selectedDifficult[index2],
													parseFloat(e.custom_difficulty)
												)
											) {
												return true;
											}
										}
									}
								}
							}
						} else if (e.language === langSelected.langSelected) {
							return true;
						}
					}
				);

				if (categoriesSelection.selectedSubCategories.length > 0) {
					langArray = langArray.filter((e) => {
						for (let index = 0; index < categoriesSelection.selectedSubCategories.length; index++) {
							if (
								e.sub_category.includes(categoriesSelection.selectedSubCategories[index]) &&
								e.language === langSelected.langSelected
							) {
								if (selectedDifficult.length === 0) {
									return true;
								} else {
									for (let index2 = 0; index2 < selectedDifficult.length; index2++) {
										if (
											getDifficultNumber(selectedDifficult[index2], parseFloat(e.custom_difficulty))
										) {
											return true;
										}
									}
								}
							}
						}
					});
				}

				if (subView === 2) {
					langArray = langArray.filter((e) => {
						if (selectedDifficult.length === 0) {
							return true;
						} else {
							for (let index = 0; index < selectedDifficult.length; index++) {
								if (getDifficultNumber(selectedDifficult[index], parseFloat(e.custom_difficulty))) {
									return true;
								}
							}
						}
					});
				}

				langArray = langArray.filter((e) => {
					if (
						!lastOptions.dominated &&
						!lastOptions.toLearn &&
						!lastOptions.newWords &&
						!lastOptions.period
					) {
						return true;
					} else {
						if (lastOptions.dominated && e.dominated === 3) {
							return true;
						} else if (lastOptions.newWords && e.dominated === null) {
							return true;
						} else if (lastOptions.toLearn && e.dominated !== 3) {
							return true;
						} else if (lastOptions.period && periodWordIds.includes(e.id)) {
							return true;
						}
						return false;
					}
				});

				setSelectedLang({ ...langSelected, langArray: langArray });
			} else {
				console.log("here");
				// if (editQuiz === undefined) {
				// 	setSelectedLang({ ...langSelected, langArray: undefined, langArrayBase: undefined })
				// }
			}
		} catch (error) {
			console.error("error", error);
			alert("Ha ocurrido un error.");
		}
	}, [
		categoriesSelection.selectedCategories,
		categoriesSelection.selectedSubCategories,
		selectedDifficult,
		lastOptions.dominated,
		lastOptions.toLearn,
		lastOptions.period,
		lastOptions.newWords,
		periodWordIds,
	]);

	useEffect(() => {
		setSelectedLang({ langSelected: "none", langArray: undefined, langArrayBase: undefined });
		setLastOptions({
			newWords: false,
			toLearn: false,
			dominated: false,
			period: false,
			answerType: "selected-lang",
		});
	}, [subView]);

	useEffect(() => {
		const handleQuizName = async () => {
			let finalArrayWords = JSON.parse(
				JSON.stringify(langSelected.langArray!.sort((a, b) => 0.5 - Math.random()))
			);
			const segments = await getSegmentsArray(finalArrayWords);
			let seminary = romanize(props.num) + ". ";
			let Qname = await getDynamicQuizName(segments);
			Qname = seminary + Qname;

			const q = query(
				collection(db, "quiz"),
				where("name", "<=", Qname + "(\uf8ff"),
				where("name", ">=", Qname),
				where("userId", "==", props.user.id),
				where("seminaryNum", "==", parseInt(props.num))
			);

			const snap = await getDocs(q);

			if (snap.size > 0) {
				Qname = Qname + ` (${snap.size})`;
			}
			setName(Qname);
		};

		const handleCategories = async () => {
			let arrayTemp = await getCategoriesByArray(langSelected.langArray!);
			setCategoriesBackUp(arrayTemp);
		};

		if (testView === 2 && (!userEditName || name.length === 0)) {
			handleQuizName();
		}
		if (testView === 2 && categoriesSelection.selectedCategories.length === 0) {
			handleCategories();
		}
	}, [testView]);

	const handlePeriod = async () => {
		if (!props.user?.vocabularyUserId || !langSelected?.langSelected) {
			console.error("Missing user ID or selected language");
			return;
		}
		const params: StudyWordsFilters = {
			userId: Number(props.user.vocabularyUserId),
			language: langSelected.langSelected,
			...(period === TimePeriod.LAST_MINUTES && minutes ? { minuteInterval: minutes } : {}),
			startDate: dateRange[0].startDate?.toISOString(),
			endDate: dateRange[0].endDate?.toISOString(),
			timePeriod: period,
		};

		try {
			if (period === TimePeriod.NONE) {
				setPeriodWordIds([]);

				toggleSelectAll(false);
				return;
			}
			const response = await getWordStudiedPerPeriod(params);

			console.log("Response",response)
			if (!response?.data?.words) {
				console.error("No se encontraron palabras en la respuesta");
				return;
			}

			const words = response.data.words;
			// Extraemos solo el campo 'word_data_id'
			const ids = words.map((w: any) => w.word_data_id);

			// Guardamos estos IDs en el estado correspondiente
			setPeriodWordIds(ids);
			setStudyDateRange(response.data.dateRange);
			// Activamos el flag para que se aplique el filtro de periodo

			setLastOptions((prevOptions) => ({ ...prevOptions, period: true }));
		} catch (error) {
			console.error("Error fetching studied words:", error);
		}
	};

	useEffect(() => {
		if (!period) return;
		
		// Si es LAST_MINUTES pero no hay minutos seleccionados, no hacer nada
		if (period === TimePeriod.LAST_MINUTES && !minutes) return;
		
		// En cualquier otro caso, ejecutar handlePeriod
		handlePeriod();
	  }, [period, dateRange, minutes]);
	return (
		<div
			className="vocabulary-seminary-main-container personalized-test-main-container"
			style={{ height: "100vh", overflowY: "auto" }}
			ref={containerRef}
		>
			{testView == 1 && <GoUpButton containerRef={containerRef} />}

			<div className="d-flex justify-content-start w-100">
				<button className="goback-btn m-0" onClick={() => props.setView(1)}>
					Atrás
				</button>
			</div>
			<div className="header-container">
				<div className="title-container">
					<h1 className="d-flex align-items-center" style={{ gap: 10, color: "#ca5050" }}>
						<img src={ListIconVocabulary} alt="icono de lista" />
						Seminario
						<span style={{ fontFamily: "initial", alignSelf: "end", lineHeight: "52px" }}>
							{romanize(props.num)}
						</span>
					</h1>
					<div className="time-container">
						<h3>
							{langSelected.langArray !== undefined ? langSelected.langArray.length : 0} términos
							seleccionados
						</h3>
					</div>
				</div>
			</div>
			{testView === 1 && (
				<>
					<SeeWordsModal
						show={seeWordsModal.show}
						onHide={() => setSeeWordsModal({ ...seeWordsModal, show: false })}
						words={seeWordsModal.wordsArray}
						selected={seeWordsModal.selected}
					/>
					<div className="personalized-test-card">
						<div className="content">
							<div className="test-row name-input-and-total-learned">
								<div className="name-input-container" style={{ flex: 1 }}>
									<input
										className="name-input"
										type="text"
										value={name}
										onChange={(e) => onChangeNameQuiz(e)}
										id={"quiz-name"}
										name={"quiz-name"}
									/>
									<label htmlFor="quiz-name" style={{ cursor: "pointer" }}>
										<img src={CreateIcon} alt="" />
									</label>
								</div>
								{subView === 2 && (
									<div>
										<h3>Términos dominados</h3>
										<div className="total-learned-text-container">
											<h3>{props.allWordsArray.filter((e) => e.dominated === 3).length}</h3>
											<h3>/</h3>
											<h3>{props.allWordsArray.length}</h3>
										</div>
									</div>
								)}
							</div>
							<div className="test-row mb-2" style={{ flexWrap: "wrap" }}>
								<h2 className="m-0">Configuración de la ronda de estudio</h2>
								<div className="subView-container">
									<div
										className={"subview-1" + (subView === 2 ? " active" : "")}
										onClick={() => setSubView(2)}
									>
										<p>Selección rápida</p>
									</div>
									<div
										className={"subview-2" + (subView === 1 ? " active" : "")}
										onClick={() => setSubView(1)}
									>
										<p>Selección avanzada</p>
									</div>
								</div>
							</div>
							<div className="test-row">
								<h3>Selecciona el idioma que quieres estudiar</h3>
							</div>
							<div className="test-row option justify-content-center">
								{languagesEnglish.map((lang, index) => {
									const percentage =
										props.languagesProgress !== undefined
											? props.languagesProgress[lang].dominated
											: 0;

									const newPercentage =
										props.languagesProgress !== undefined ? props.languagesProgress[lang].seen : 0;
									return (
										((langSelected.langSelected === "none" && props.words[lang].length > 0) ||
											langSelected.langSelected === lang) && (
											<div className="lang" key={"language-selector-progress-" + index}>
												<div className="circle-container" style={{ flexDirection: "column" }}>
													<h2 className="m-0">{Math.floor(percentage)}%</h2>
													<div className="progress-container">
														<canvas
															id="myChart"
															width="0%"
															style={{ position: "absolute" }}
														></canvas>
														<Tooltip>
															<Progress
																percent={newPercentage}
																success={{
																	percent: percentage,
																	strokeColor: percentage < 100 ? "#ca5050" : "#efc900",
																}}
																type="circle"
																width={70}
																strokeColor="#959595"
																strokeWidth={10}
																trailColor="#e7e7e7"
																showInfo={false}
															/>
														</Tooltip>
														{returnFlagByLanguage(lang)}
													</div>
												</div>
												<div className="language-selection" style={{ flexDirection: "row" }}>
													<input
														id={lang}
														name={lang}
														type="checkbox"
														checked={langSelected.langSelected === lang}
														onChange={onChangeLangSelected}
													/>
													<label htmlFor={lang}>
														{capitalizeFirstLetter(getLangSpanishSimple(lang))}
													</label>
												</div>
											</div>
										)
									);
								})}
							</div>
							{subView === 1 ? (
								<>
									<AdvanceConfigQuiz
										langSelected={langSelected}
										categoriesSelection={categoriesSelection}
										onChangeDifficult={onChangeDifficult}
										selectedDifficult={selectedDifficult}
										handleSeeWordsModal={handleSeeWordsModal}
										onChangeCategory={onChangeCategory}
										onChangeSubCategory={onChangeSubCategory}
										allWordsArray={props.allWordsArray}
									/>
									<div className="test-row">
										<h3>Finalmente, hay algunos otros datos que puedes personalizar</h3>
									</div>
									<div className="test-row option" style={{ gap: 0 }}>
										<div className="selects">
											<label htmlFor="">
												Responder en español{" "}
												{langSelected.langSelected !== "none" &&
													"o " + getLangSpanishSimple(langSelected.langSelected)}
											</label>
											<div className="checkboxes-container">
												<div>
													<input
														type="checkbox"
														name="spanish"
														id="spanish-lang"
														value="normal"
														checked={lastOptions.answerType === "normal"}
														onChange={(e) =>
															setLastOptions({ ...lastOptions, answerType: e.target.value })
														}
													/>
													<label htmlFor="spanish-lang">Español</label>
												</div>
												{langSelected.langSelected !== "none" && (
													<>
														<div>
															<input
																type="checkbox"
																name="selected-lang"
																id="selected-lang"
																value="selected-lang"
																checked={lastOptions.answerType === "selected-lang"}
																onChange={(e) =>
																	setLastOptions({ ...lastOptions, answerType: e.target.value })
																}
															/>
															<label htmlFor="selected-lang">
																{getLangSpanish(langSelected.langSelected)}
															</label>
														</div>
														{langSelected.langSelected === "chinese" && (
															<div>
																<input
																	type="checkbox"
																	name="kanji-lang"
																	id="kanji-lang"
																	value="kanji"
																	checked={lastOptions.answerType === "kanji"}
																	onChange={(e) =>
																		setLastOptions({ ...lastOptions, answerType: e.target.value })
																	}
																/>
																<label htmlFor="kanji-lang">Chino (Kanzi)</label>
															</div>
														)}
													</>
												)}
											</div>
										</div>
										{!props.admin && (
											<div
												className="test-row option selects"
												style={{ gap: 0, justifyContent: "flex-start", alignItems: "flex-start" }}
											>
												{/* <label htmlFor="">Ronda en nativo o idioma original</label> */}
												<div
													className=""
													style={{
														height: 46.5,
														display: "flex",
														justifyContent: "flex-start",
														alignItems: "center",
														gap: 20,
													}}
												>
													<div className="category">
														<input
															type="checkbox"
															id="lastOption-1"
															checked={lastOptions.newWords}
															onChange={(e) =>
																setLastOptions({ ...lastOptions, newWords: e.target.checked })
															}
														/>
														<label htmlFor="lastOption-1">Términos nuevos</label>
													</div>
													<div className="category">
														<input
															type="checkbox"
															id="lastOption-2"
															checked={lastOptions.dominated}
															onChange={(e) =>
																setLastOptions({ ...lastOptions, dominated: e.target.checked })
															}
														/>
														<label htmlFor="lastOption-2">Términos dominados</label>
													</div>
													<div className="category">
														<input
															type="checkbox"
															id="lastOption-3"
															checked={lastOptions.toLearn}
															onChange={(e) =>
																setLastOptions({ ...lastOptions, toLearn: e.target.checked })
															}
														/>
														<label htmlFor="lastOption-3">Términos por dominar</label>
													</div>
												</div>
											</div>
										)}
									</div>
								</>
							) : (
								langSelected.langSelected !== "none" && (
									<>
										{!props.admin && (
											<div className="general-personalized-test-progress-bar-container">
												<div className="general-personalized-test-progress-bar">
													<div
														className="general-personalized-test-progress"
														style={{
															width:
																(props.allWordsArray.filter((e) => e.dominated === 3).length /
																	props.allWordsArray.length) *
																	100 +
																"%",
														}}
													></div>
												</div>
											</div>
										)}
										<div className="test-row option" style={{ gap: 0 }}>
											<div className="selects">
												<label htmlFor="">
													Responder en español{" "}
													{langSelected.langSelected !== "none" &&
														"o " + getLangSpanishSimple(langSelected.langSelected)}
												</label>
												<div className="checkboxes-container">
													<div>
														<input
															type="checkbox"
															name="spanish"
															id="spanish-lang"
															value="normal"
															checked={lastOptions.answerType === "normal"}
															onChange={(e) =>
																setLastOptions({ ...lastOptions, answerType: e.target.value })
															}
														/>
														<label htmlFor="spanish-lang">Español</label>
													</div>
													{langSelected.langSelected !== "none" && (
														<>
															<div>
																<input
																	type="checkbox"
																	name="selected-lang"
																	id="selected-lang"
																	value="selected-lang"
																	checked={lastOptions.answerType === "selected-lang"}
																	onChange={(e) =>
																		setLastOptions({ ...lastOptions, answerType: e.target.value })
																	}
																/>
																<label htmlFor="selected-lang">
																	{getLangSpanish(langSelected.langSelected)}
																</label>
															</div>
															{langSelected.langSelected === "chinese" && (
																<div>
																	<input
																		type="checkbox"
																		name="kanji-lang"
																		id="kanji-lang"
																		value="kanji"
																		checked={lastOptions.answerType === "kanji"}
																		onChange={(e) =>
																			setLastOptions({ ...lastOptions, answerType: e.target.value })
																		}
																	/>
																	<label htmlFor="kanji-lang">Chino (Kanzi)</label>
																</div>
															)}
														</>
													)}
												</div>
											</div>
											{!props.admin && (
												<div
													className="test-row option selects"
													style={{
														gap: 0,
														justifyContent: "flex-start",
														alignItems: "flex-start",
														//width: "100%",
														padding: "0 10px",
													}}
												>
													<label htmlFor="">Incluir:</label>
													<div
														className=""
														style={{
															display: "flex",
															flexWrap: "wrap",
															gap: "15px",
															width: "100%",
														}}
													>
														<div className="category">
															<input
																type="checkbox"
																id="lastOption-1"
																checked={lastOptions.newWords}
																onChange={(e) =>
																	setLastOptions({ ...lastOptions, newWords: e.target.checked })
																}
															/>
															<label htmlFor="lastOption-1">Términos nuevos</label>
														</div>
														<div className="category">
															<input
																type="checkbox"
																id="lastOption-2"
																checked={lastOptions.dominated}
																onChange={(e) =>
																	setLastOptions({ ...lastOptions, dominated: e.target.checked })
																}
															/>
															<label htmlFor="lastOption-2">Términos dominados</label>
														</div>
														<div className="category">
															<input
																type="checkbox"
																id="lastOption-3"
																checked={lastOptions.toLearn}
																onChange={(e) =>
																	setLastOptions({ ...lastOptions, toLearn: e.target.checked })
																}
															/>
															<label htmlFor="lastOption-3">Términos por dominar</label>
														</div>
														{
															<CustomSelect
																period={period}
																studyDateRange={studyDateRange}
																selectPeriod={setPeriod}
																setMinutes={setMinutes}
																lang={langSelected.langSelected}
																minutes={minutes}
																dateRange={dateRange}
																handleSelect={handleSelect}
															/>
														}
													</div>
												</div>
											)}
										</div>
										{langSelected.langArrayBase !== undefined &&
											langSelected.langArrayBase.filter((e) => e.custom_difficulty === null)
												.length !== langSelected.langArrayBase.length && (
												<div className="test-row">
													<h3>
														Selecciona la dificultad de los términos en la ronda de estudio (Puedes
														escoger más de una)
													</h3>
												</div>
											)}
										{langSelected.langArrayBase !== undefined && (
											<div className="test-row option">
												{langSelected.langArrayBase.some(
													(e) =>
														parseFloat(e.custom_difficulty) <= 1 &&
														parseFloat(e.custom_difficulty) > 0.9
												) && (
													<div className="lang">
														<img src={VeryEasy} alt="icono de facil" />
														<div>
															<input
																onChange={onChangeDifficult}
																id="very-easy"
																checked={selectedDifficult.includes("very-easy")}
																name="very-easy"
																type="checkbox"
															/>
															<label htmlFor="very-easy">Muy fácil</label>
														</div>
													</div>
												)}
												{langSelected.langArrayBase.some(
													(e) =>
														parseFloat(e.custom_difficulty) <= 0.9 &&
														parseFloat(e.custom_difficulty) > 0.8
												) && (
													<div className="lang">
														<img src={EasyDifficult} alt="icono de facil" />
														<div>
															<input
																onChange={onChangeDifficult}
																id="easy"
																checked={selectedDifficult.includes("easy")}
																name="easy"
																type="checkbox"
															/>
															<label htmlFor="easy">Fácil</label>
														</div>
													</div>
												)}
												{langSelected.langArrayBase.some(
													(e) =>
														parseFloat(e.custom_difficulty) <= 0.8 &&
														parseFloat(e.custom_difficulty) > 0.7
												) && (
													<div className="lang">
														<img src={NormalDifficult} alt="icono de dificultad mediana" />
														<div>
															<input
																onChange={onChangeDifficult}
																id="normal"
																checked={selectedDifficult.includes("normal")}
																name="normal"
																type="checkbox"
															/>
															<label htmlFor="normal">Intermedia baja</label>
														</div>
													</div>
												)}
												{langSelected.langArrayBase.some(
													(e) =>
														parseFloat(e.custom_difficulty) <= 0.7 &&
														parseFloat(e.custom_difficulty) > 0.5
												) && (
													<div className="lang">
														<img src={NormalDifficult} alt="icono de dificultad mediana" />
														<div>
															<input
																onChange={onChangeDifficult}
																id="normal-high"
																checked={selectedDifficult.includes("normal-high")}
																name="normal-high"
																type="checkbox"
															/>
															<label htmlFor="normal-high">Intermedia alta</label>
														</div>
													</div>
												)}
												{langSelected.langArrayBase.some(
													(e) =>
														parseFloat(e.custom_difficulty) <= 0.5 &&
														parseFloat(e.custom_difficulty) > 0.3
												) && (
													<div className="lang">
														<img src={HardDifficult} alt="icono de dificultad dificil" />
														<div>
															<input
																onChange={onChangeDifficult}
																id="hard"
																checked={selectedDifficult.includes("hard")}
																name="hard"
																type="checkbox"
															/>
															<label htmlFor="hard">Difícil</label>
														</div>
													</div>
												)}
												{langSelected.langArrayBase.some(
													(e) => parseFloat(e.custom_difficulty) <= 0.3
												) && (
													<div className="lang">
														<img src={Expert} alt="icono de dificultad dificil" />
														<div>
															<input
																onChange={onChangeDifficult}
																id="expert"
																checked={selectedDifficult.includes("expert")}
																name="expert"
																type="checkbox"
															/>
															<label htmlFor="expert">Muy difícil</label>
														</div>
													</div>
												)}
											</div>
										)}
										<div className="additional-options-row">
											<div className="container">
												<button
													className="action-btn secondary-btn-bg mr-2"
													onClick={() => toggleSelectAll(true)}
												>
													Seleccionar todos
												</button>
												<button
													className="action-btn secondary-btn-bg"
													onClick={() => toggleSelectAll(false)}
												>
													Deseleccionar todos
												</button>
											</div>
											<Form
												className="translate-input-container my-2 d-flex justify-content-end align-items-center  flex-column"
												style={{ gap: "10px" }}
											>
												<Form.Check
													type="switch"
													id="custom-switch-difficulty"
													checked={showCustomDifficulty}
													onChange={(e) => setShowCustomDifficulty(e.target.checked)}
													label={"Mostrar valores de dificultad"}
												/>
											</Form>
										</div>
										<div className="test-subview-2-categories-cards-container">
											{categoriesSelection.avaibleCategories.map((cat, index) => {
												return (
													<CategoryCard
														langSelected={langSelected}
														handleCategorySelector={handleCategorySelector}
														cat={cat}
														index={index}
														avaibleCategories={categoriesSelection.avaibleCategories}
														handleSelectAll={handleSelectAll}
														key={"category-card-" + cat}
														showCustomDifficulty={showCustomDifficulty}
													/>
												);
											})}
										</div>
									</>
								)
							)}
						</div>
					</div>
					<div className="button-test-container">
						<button
							disabled={
								langSelected.langSelected === "none" ||
								(langSelected.langArray !== undefined && langSelected.langArray.length === 0) ||
								creatingLoader
							}
							onClick={!props.admin ? testPreview : createDefaultQuiz}
							style={
								langSelected.langSelected === "none" ||
								(langSelected.langArray !== undefined && langSelected.langArray.length === 0) ||
								creatingLoader
									? { background: "gray" }
									: {}
							}
						>
							Crear
						</button>
					</div>
				</>
			)}

			{testView === 2 && (
				<>
					<div className="personalized-test-card create-test-card">
						<div className="content">
							<div className="name-input-container">
								<input
									className="name-input"
									type="text"
									value={name}
									onChange={(e) => setName(e.target.value)}
									id={"quiz-name"}
									name={"quiz-name"}
									style={{ border: "none" }}
								/>
								<label htmlFor="quiz-name" style={{ cursor: "pointer" }}>
									<img src={CreateIcon} alt="" />
								</label>
							</div>
							<div className="test-row option">
								{langSelected.langSelected === "french" && (
									<div className="lang">
										<img src={FranceFlagSVG} alt="bandera de francia" />
										<div>
											<label htmlFor="french">Francés</label>
										</div>
									</div>
								)}
								{langSelected.langSelected === "italian" && (
									<div className="lang">
										<img src={ItalyFlagSVG} alt="bandera de japón" />
										<div>
											<label htmlFor="italian">Italiano</label>
										</div>
									</div>
								)}
								{langSelected.langSelected === "portuguese" && (
									<div className="lang">
										<img src={BrazilFlagSVG} alt="bandera de portugal" />
										<div>
											<label htmlFor="portuguese">Portugués</label>
										</div>
									</div>
								)}
								{langSelected.langSelected === "german" && (
									<div className="lang">
										<img src={GermanyFlagSVG} alt="bandera de alemania" />
										<div>
											<label htmlFor="german">Alemán</label>
										</div>
									</div>
								)}
								{langSelected.langSelected === "japanese" && (
									<div className="lang">
										<img src={JapanFlagSVG} alt="bandera de alemania" />
										<div>
											<label htmlFor="japanese">Japonés</label>
										</div>
									</div>
								)}
								{langSelected.langSelected === "chinese" && (
									<div className="lang">
										<img src={ChinaFlagSVG} alt="bandera de alemania" />
										<div>
											<label htmlFor="chinese">Chino</label>
										</div>
									</div>
								)}
								{langSelected.langSelected === "russia" && (
									<div className="lang">
										<img src={RussiaFlagSVG} alt="bandera de alemania" />
										<div>
											<label htmlFor="russia">Ruso</label>
										</div>
									</div>
								)}
								{langSelected.langSelected === "english" && (
									<div className="lang">
										<img src={UnitedStatesFlagSVG} alt="bandera de alemania" />
										<div>
											<label htmlFor="english">Inglés</label>
										</div>
									</div>
								)}
							</div>
							<div className="test-row">
								<h3>Categorías en la ronda de estudio</h3>
							</div>
							<div className="test-row option">
								{categoriesSelection.selectedCategories.length > 0
									? categoriesSelection.selectedCategories.map((category, index) => {
											return (
												<div className="category" key={"category-creted-test-" + index}>
													<label htmlFor="verbs">{capitalizeFirstLetter(category)}</label>
												</div>
											);
									  })
									: categoriesBackUp.map((category, index) => {
											return (
												<div className="category" key={"category-creted-test-" + index}>
													<label htmlFor="verbs">{capitalizeFirstLetter(category)}</label>
												</div>
											);
									  })}
							</div>
							<div className="test-row option">
								{langSelected.langArray!.filter(
									(e) =>
										parseFloat(e.custom_difficulty) <= 1 && parseFloat(e.custom_difficulty) > 0.9
								).length > 0 && (
									<div className="lang">
										<img src={VeryEasy} alt="icono de dificultad dificil" />
										<div>
											<label htmlFor="very-easy">Muy fácil</label>
										</div>
									</div>
								)}
								{langSelected.langArray!.filter(
									(e) =>
										parseFloat(e.custom_difficulty) <= 0.9 && parseFloat(e.custom_difficulty) > 0.8
								).length > 0 && (
									<div className="lang">
										<img src={EasyDifficult} alt="icono de dificultad dificil" />
										<div>
											<label htmlFor="easy">Fácil</label>
										</div>
									</div>
								)}
								{langSelected.langArray!.filter(
									(e) =>
										parseFloat(e.custom_difficulty) <= 0.8 && parseFloat(e.custom_difficulty) > 0.7
								).length > 0 && (
									<div className="lang">
										<img src={NormalDifficult} alt="icono de dificultad difícil" />
										<div>
											<label htmlFor="normal">Mediana baja</label>
										</div>
									</div>
								)}
								{langSelected.langArray!.filter(
									(e) =>
										parseFloat(e.custom_difficulty) <= 0.7 && parseFloat(e.custom_difficulty) > 0.5
								).length > 0 && (
									<div className="lang">
										<img src={NormalDifficult} alt="icono de dificultad difícil" />
										<div>
											<label htmlFor="normal-high">Mediana alta</label>
										</div>
									</div>
								)}
								{langSelected.langArray!.filter(
									(e) =>
										parseFloat(e.custom_difficulty) <= 0.5 && parseFloat(e.custom_difficulty) > 0.3
								).length > 0 && (
									<div className="lang">
										<img src={HardDifficult} alt="icono de dificultad dificil" />
										<div>
											<label htmlFor="hard">Difícil</label>
										</div>
									</div>
								)}
								{langSelected.langArray!.filter((e) => parseFloat(e.custom_difficulty) <= 0.3)
									.length > 0 && (
									<div className="lang">
										<img src={Expert} alt="icono de dificultad dificil" />
										<div>
											<label htmlFor="expert">Muy difícil</label>
										</div>
									</div>
								)}
							</div>
							<div className="test-row">
								<h3>Otras características</h3>
							</div>
							<div className="test-row characteristics">
								<h3>
									<ul>
										{lastOptions.answerType === "normal" && (
											<li>
												Los términos estarán en el idioma{" "}
												{getLangSpanishSimple(langSelected.langSelected)}, deberás dar su traducción
												al español.
											</li>
										)}
										{lastOptions.answerType === "selected-lang" && (
											<li>
												Los términos estarán en español, deberás dar su traducción en el idioma{" "}
												{getLangSpanishSimple(langSelected.langSelected)}.
											</li>
										)}
										<li>
											Estarán incluidos los términos que has dominado y términos aún por dominar.
										</li>
									</ul>
								</h3>
							</div>
							<div className="test-row number-of-words">
								<div className="add-remove-words">
									<img
										className={wordsNum === 0 ? "disabled" : ""}
										src={RemoveCircle}
										alt="boton de menos"
										onClick={() =>
											wordsNum > 0 ? setWordsNum(wordsNum - 1) : setWordsNum(wordsNum)
										}
									/>
									<input type="number" value={wordsNum} onChange={changeWordsNum} />
									<img
										className={wordsNum === langSelected.langArray?.length ? "disabled" : ""}
										src={AddCircle}
										alt="boton de sumar"
										onClick={() =>
											wordsNum < langSelected.langArray!.length
												? setWordsNum(wordsNum + 1)
												: setWordsNum(wordsNum)
										}
									/>
								</div>
								<h2>Términos en la ronda</h2>
							</div>
						</div>
					</div>
					<div className="button-test-container">
						<button className="change-test" onClick={() => setTestView(1)}>
							Editar
						</button>
						<button
							disabled={wordsNum.toString() === "NaN" || wordsNum === 0 || loading}
							onClick={createTest}
							style={
								wordsNum.toString() === "NaN" || wordsNum === 0 || loading
									? { background: "gray" }
									: {}
							}
						>
							Empezar
						</button>
					</div>
				</>
			)}
		</div>
	);
}

export default PersonalizedTest;
