import { useEffect, useRef, useState } from "react";
import {
	getLanguages,
	getLanguage,
	getCategories,
	getSubCategories,
	wordsSeminary,
	getSubCategoriesBySeminary,
} from "@api/Vocabulary";
import { useParams } from "react-router-dom";
import "./VocabularyQuizStyle.scss";
import { addDoc, collection, deleteDoc, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { getSegmentsArray } from "@containers/VocabularySeminaryContainer/functions/VocabularyFunctions";
import { language, languagesArrays } from "interfaces/Interfaces";
import PersonalizedTest from "@components/Vocabulary/PersonalizedTest/PersonalizedTest";
import { defaultQuiz, DefaultQuizzesMain } from "./components/DefaultQuizzesMain/DefaultQuizzesMain";

function VocabularyQuizContainer() {
	const { seminaryNum } = useParams<any>();
	const [words, setWords] = useState<languagesArrays>();
	const [categories, setCategories] = useState<Array<string>>(new Array());
	const [subCategories, setSubCategories] = useState<Array<string>>(new Array());
	const [allWordsArray, setAllWordsArray] = useState<Array<any>>();
	const [loading, setLoading] = useState<boolean>(true);
	const [view, setView] = useState<number>(1);
	const [editQuiz, setEditQuiz] = useState<defaultQuiz>();

	const getSeminary = async () => {
		try {
			await wordsSeminary(seminaryNum)
				.then(async (res) => {
					let wordsArray = new Array();
					let index = 0;
					let frenchArray = new Array<language>();
					let germanArray = new Array<language>();
					let italianArray = new Array<language>();
					let japaneseArray = new Array<language>();
					let chineseArray = new Array<language>();
					let russianArray = new Array<language>();
					let portugueseArray = new Array<language>();
					let englishArray = new Array<language>();

					await Promise.all(
						res.seminary.map((word) => {
							if (wordsArray.findIndex((e) => e.id === word.word_data_id) === -1) {
								const data = {
									id: word.word_data_id,
									category: new Array(word.categoria),
									singular_category: word.singular_category,
									sub_category: new Array(word.sub_categoria),
									dominated: null,
									language: word.idioma,
									native_word: word.palabra,
									optional_word: word.palabra_opcional,
									meaning: new Array(word.significado),
									difficult: word.dificultad,
									word_id: word.id,
									warning: word.advertencia,
								};
								wordsArray.push(data);
							} else {
								const index = wordsArray.findIndex((e) => e.id === word.word_data_id);
								if (wordsArray[index].category.includes(word.categoria) === false) {
									wordsArray[index].category.push(word.categoria);
								}
								if (wordsArray[index].sub_category.includes(word.sub_categoria) === false) {
									wordsArray[index].sub_category.push(word.sub_categoria);
								}
								if (wordsArray[index].meaning.includes(word.significado) === false) {
									wordsArray[index].meaning.push(word.significado);
								}
							}
						})
					);
					wordsArray.forEach((word) => {
						console.log("Woooord", word);
						switch (word.language) {
							case "french":
								frenchArray.push(word);
								break;
							case "german":
								germanArray.push(word);
								break;
							case "italian":
								italianArray.push(word);
								break;
							case "japanese":
								japaneseArray.push(word);
								break;
							case "chinese":
								chineseArray.push(word);
								break;
							case "russian":
								russianArray.push(word);
								break;
							case "portuguese":
								portugueseArray.push(word);
								break;
							case "english":
								englishArray.push(word);
								break;
						}
					});
					setWords({
						french: frenchArray,
						german: germanArray,
						italian: italianArray,
						japanese: japaneseArray,
						chinese: chineseArray,
						russian: russianArray,
						english: englishArray,
						portuguese: portugueseArray,
					});
					setAllWordsArray([
						...frenchArray,
						...germanArray,
						...italianArray,
						...japaneseArray,
						...chineseArray,
						...russianArray,
						...englishArray,
						...portugueseArray,
					]);
					getCategoriesBySeminary();
					getSubCategories();
					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					alert("Ha ocurrido un error.");
				});
		} catch (error) {
			console.error(error);
			alert("Ha ocurrido un error.");
		}
	};

	const getCategoriesBySeminary = () => {
		let categoriesArray = new Array();
		getCategories(seminaryNum).then((res) => {
			res.categories.forEach((category) => {
				categoriesArray.push(category.categoria);
			});

			setCategories(categoriesArray);
		});
	};

	const handleEditQuiz = (quiz: defaultQuiz) => {
		setEditQuiz(quiz);
		setView(2);
	};

	

	const getSubCategories = () => {
		let subCategoriesArray = new Array();
		getSubCategoriesBySeminary(seminaryNum).then((res) => {
			res.subCategories.forEach((subCategory) => {
				subCategoriesArray.push(subCategory.sub_categoria);
			});

			setSubCategories(subCategoriesArray);
		});
	};



	useEffect(() => {
		getSeminary();
	}, []);

	if (loading)
		return (
			<div style={{ height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);

	return (
		<>
			{view === 1 && (
				<DefaultQuizzesMain
					num={seminaryNum}
					setView={setView}
					handleEditQuiz={handleEditQuiz}
					editQuiz={editQuiz}
					setEditQuiz={setEditQuiz}
				/>
			)}
			{view === 2 && (
				<PersonalizedTest
					num={seminaryNum}
					words={words}
					allWordsArray={allWordsArray}
					categories={categories}
					subCategories={subCategories}
					admin={true}
					setView={setView}
					editQuiz={editQuiz}
				/>
			)}
		</>
	);
}

export default VocabularyQuizContainer;
