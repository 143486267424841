import { Spinner20vh, SpinnerCustom } from "@components/Spinner/SpinnerMyAccountView";
import { BackArrowIcon } from "assets/Imports";
import { query, collection, getDocs, where } from "firebase/firestore";
import { db } from "initFirebase";
import { RefObject, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import TRow from "../TRow/TRow";
import "./StudentsTableStyles.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { GoUpButton } from "@components/Buttons/GoUpButton";

interface StudentsTableProps {
	setMenu: (menu: number) => void;
	containerRef: RefObject<HTMLDivElement>; // Agregar tipo correcto
  }
  
function StudentsTable({ setMenu ,containerRef}:StudentsTableProps) {
	const [users, setUsers] = useState(new Array()); // This array will contain the next 20 users
	const [usersCopy, setUsersCopy] = useState(new Array()); // This array will always contain all users
	const [searchText, setSearchText] = useState<string>("");
	const [debouncedText, setDebouncedText] = useState("");
	console.log("ref", containerRef);	
	const getUsers = async () => {
		let index = 0;
		const array = new Array();

		const q = query(collection(db, "users"));
		const querySnapshot = await getDocs(q);

		querySnapshot.forEach(async (doc) => {
			index++;

			const data: any = {
				...doc.data(),
				id: doc.id,
				vocabularyStreak:
					doc.data().vocabularyStreak !== undefined ? doc.data().vocabularyStreak : 0,
				studiedDays: doc.data().studiedDays === undefined ? [] : doc.data().studiedDays,
				modules: 0,
			};

			array.push(data);

			if (index === querySnapshot.size) {
				array.forEach(async (student, index) => {
					let totalPay = 0;
					let modules = 0;

					const q2 = query(
						collection(db, "paymentHistory"),
						where("userId", "==", student.id),
						where("verified", "==", true)
					);
					const snap = await getDocs(q2);

					snap.forEach((pay) => {
						totalPay = totalPay + parseInt(pay.data().amount);
					});

					if ((snap.size === 1 && totalPay === 12000) || student.promotion === true) {
						modules = 5;
					} else {
						if (totalPay >= 3000 && totalPay < 7500) {
							modules = 1;
						} else if (totalPay >= 7500 && totalPay < 12000) {
							modules = 2;
						} else if (totalPay >= 12000 && totalPay < 15000) {
							modules = 3;
						} else if (totalPay >= 15000 && totalPay < 18000) {
							modules = 4;
						} else if (totalPay == 18000) {
							modules = 5;
						} else {
							modules = 0;
						}
					}

					array[index].modules = modules;

					if (index === array.length - 1) {
						array.sort(compare);
						setUsersCopy(array);
						setUsers(array);
					}
				});
			}
		});
	};

	// const loadMore = () => { // function responsible for loadind more content when using InfiniteScroll component
	// 	let tempArray = [...users]
	// 	setUsersCopy2(usersCopy2.concat(tempArray.splice(0,20)));
	// 	users.splice(0,20);
	// }

	function compare(a, b) {
		if (a.name.toLowerCase() < b.name.toLowerCase()) {
			return -1;
		}
		if (a.name.toLowerCase() > b.name.toLowerCase()) {
			return 1;
		}
		return 0;
	}

	const arraySearch = (array, keyword) => {
		const searchTerm = keyword.replace(/[+\s]/g, "");
		return array.filter((value) => {
			if (
				value.name
					.toLowerCase()
					.replace(/[+\s]/g, "")
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.includes(searchTerm, "g") ||
				value.phone.replace(/[+\s]/g, "").includes(searchTerm, "g") ||
				value.email.toLowerCase().includes(searchTerm.toLowerCase())
			) {
				return true;
			}
		});
	};

	const handleOnChange = async () => {
		setUsers([]);
		let search = await arraySearch(usersCopy, debouncedText);
		setUsers(search);
	};

	useEffect(() => {
		const timerId = setTimeout(() => {
			setDebouncedText(searchText);
		}, 500);

		return () => {
			clearTimeout(timerId);
		};
	}, [searchText]);

	useEffect(() => {
		if (debouncedText.length > 1) {
			handleOnChange();
		} else {
			setUsers(usersCopy);
		}
	}, [debouncedText]);

	useEffect(() => {
		getUsers();
	}, []);

	return (
		<>

			<button onClick={() => setMenu(1)} className="goback-btn">
				{"Atrás"}
			</button>
			<div className="search-all-users-container d-flex flex-column">
				<label htmlFor="search-all-users">Buscar:</label>
				<input
					className="search-input-all-users"
					type="text"
					name="search-all-users"
					id="search-all-users"
					value={searchText}
					onChange={(e) => setSearchText(e.target.value)}
					placeholder="Nombre, correo o celular"
				/>
			</div>
		
				<GoUpButton containerRef={containerRef} />
				<Table hover>
					<thead>
						<tr>
							<th>Nombre</th>
							<th>Correo</th>
							<th className="text-center">Perfil</th>
							<th>Celular</th>
							<th>Matrícula</th>
							<th>Horario</th>
							<th>Última actividad</th>
							<th className="text-center">Módulos Pagados</th>
							<th className="text-center">1° Formulario</th>
							<th className="text-center">2° Formulario</th>
						</tr>
					</thead>
					{/* <InfiniteScroll
						className="infinite-scroll-container"
						dataLength={usersCopy2.length}
						next={loadMore}
						hasMore={users.length > 0}
						loader={<SpinnerCustom height={100} />}
						scrollThreshold={1}
					> */}
					<tbody>
						{users.length > 0 &&
							users.map((user, index) => {
								return (
									<TRow
										index={index}
										studentInfo={user}
										key={"userRow-" + index}
										getUsers={() => getUsers()}
									/>
								);
							})}
					</tbody>
					{/* </InfiniteScroll> */}
				</Table>
		

				{users.length === 0 && <Spinner20vh />}
		
		</>
	);
}

export default StudentsTable;
