import React, { useState, useRef, useEffect, useCallback } from "react";
import { DateRange } from "react-date-range";
import { MinuteIntervals, TimePeriod } from "../common/enums/studyPeriods.enum";
import {
	fr, // Francés
	de, // Alemán
	ja, // Japonés
	it, // Italiano
	ru, // Ruso
	zhCN, // Chino
	pt, // Portugués
	enUS, // Inglés
} from "date-fns/locale";
import { calculatePreviewDateRange } from "../functions/functions";

interface props {
	period?: TimePeriod;
	selectPeriod?: (e: TimePeriod) => void;
	dateRange: DateRange;
	setMinutes: (e: MinuteIntervals) => void;
	lang: string;
	minutes: MinuteIntervals;
	handleSelect: (ranges: any) => void;
	studyDateRange: {
		startDate: string;
		endDate: string;
	};
}

export const CustomSelect = (props: props) => {
	const {
		period,
		selectPeriod,
		dateRange,
		lang,
		minutes,
		setMinutes,
		handleSelect,
		studyDateRange,
	} = props;
	const { CUSTOM, PREVIOUS_DAY, PREVIOUS_WEEK, PREVIOUS_MONTH, NONE, LAST_MINUTES } = TimePeriod;
	const { NONE: NONE_MINUTES } = MinuteIntervals;
	const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
	const [isMinutesOpen, setIsMinutesOpen] = useState(false);

	const [isFocused, setIsFocused] = useState(false);
	const [isSelectOpen, setIsSelectOpen] = useState(false);
	const selectContainerRef = useRef<HTMLDivElement>(null);
	const dateRangeRef = useRef<HTMLDivElement>(null);
	const minutesRef = useRef<HTMLDivElement>(null);

	const MINUTE_OPTIONS = [
		{ value: 5, label: "5 minutos" },
		{ value: 10, label: "10 minutos" },
		{ value: 20, label: "20 minutos" },
		{ value: 25, label: "25 minutos" },
		{ value: 30, label: "30 minutos" },
	];

	useEffect(() => {
		setIsDateRangeOpen(period === CUSTOM && isFocused);
		// Resetear minutos cuando cambie el periodo
		if (period !== LAST_MINUTES) {
			setIsMinutesOpen(false);
		}
	}, [period, isFocused]);

	const handleSelectClick = (e: React.MouseEvent<HTMLSelectElement>) => {
		if (period === CUSTOM) {
			setIsFocused(true);
			setIsDateRangeOpen(true);
		} else if (period === LAST_MINUTES) {
			setIsMinutesOpen(true);
		} else {
			setIsSelectOpen(!isSelectOpen);
			setIsFocused(!isFocused);
		}
	};

	const localesByName = {
		french: fr,
		german: de,
		japanese: ja,
		italian: it,
		russian: ru,
		chinese: zhCN,
		portuguese: pt,
		english: enUS,
	};

	const handleMinutesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const value = Number(e.target.value) as MinuteIntervals;
		if (value === NONE_MINUTES) {
			setIsMinutesOpen(false);
			return;
		}
		setMinutes(value);
		setIsMinutesOpen(false);
	};

	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const newValue = e.target.value as TimePeriod;

		// Manejar la selección de NONE
		if (newValue === NONE) {
			selectPeriod && selectPeriod(newValue);
			return;
		}

		// Resetear estados según el periodo seleccionado
		const resetStates = () => {
			// Resetear dateRange si existe y no es periodo personalizado
			if (newValue !== CUSTOM && dateRange[0].startDate) {
				handleSelect({
					selection: {
						startDate: null,
						endDate: null,
						key: "selection",
					},
				});
			}

			// Actualizar estados de UI
			if (newValue === CUSTOM) {
				setIsFocused(true);
				setIsDateRangeOpen(true);
			} else if (newValue === LAST_MINUTES) {
				setIsMinutesOpen(true);
			} else {
				setIsFocused(false);
				setIsDateRangeOpen(false);
				setIsMinutesOpen(false);
			}
		};

		resetStates();
		selectPeriod && selectPeriod(newValue);
	};

	const handleClickOutside = useCallback((event: MouseEvent) => {
		if (
			!selectContainerRef.current?.contains(event.target as Node) &&
			!dateRangeRef.current?.contains(event.target as Node) &&
			!minutesRef.current?.contains(event.target as Node)
		) {
			setIsFocused(false);
			setIsSelectOpen(false);
			setIsDateRangeOpen(false);
			setIsMinutesOpen(false);
		}
	}, []);

	function formatDate(isoString: string): string {
		if (!isoString) return "";

		try {
			// Crear una fecha UTC directamente desde el string ISO
			const date = new Date(isoString);

			// Usar UTC methods para obtener los componentes de la fecha
			const day = date.getUTCDate().toString().padStart(2, "0");
			const month = date.toLocaleString("es-ES", { month: "short", timeZone: "UTC" });
			const year = date.getUTCFullYear();

			return `${day}/${month}/${year}`;
		} catch (error) {
			console.error("Error formatting date:", error);
			return "";
		}
	}

	// Función para renderizar la opción con su rango de fechas
	const renderPeriodOption = (periodType: TimePeriod) => {
		const labels = {
			[TimePeriod.PREVIOUS_DAY]: "Día anterior",
			[TimePeriod.PREVIOUS_WEEK]: "Semana anterior",
			[TimePeriod.PREVIOUS_MONTH]: "Mes anterior",
			[TimePeriod.CUSTOM]: "Personalizado",
			[TimePeriod.LAST_MINUTES]: "Últimos minutos",
		};

		// Si es el periodo seleccionado, usar las fechas del backend
		if (period === periodType && studyDateRange.startDate) {
			return `${labels[periodType]} (${formatDate(studyDateRange.startDate)} - ${formatDate(
				studyDateRange.endDate
			)})`;
		}

		// Si no está seleccionado, mostrar preview
		const previewRange = calculatePreviewDateRange(periodType);
		if (previewRange.startDate) {
			return `${labels[periodType]} (${formatDate(previewRange.startDate)} - ${formatDate(
				previewRange.endDate
			)})`;
		}

		return labels[periodType];
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [handleClickOutside]);

	return (
		<div ref={selectContainerRef} className={`position-relative`}>
			<div className="d-flex align-items-center gap-5 w-100" style={{ gap: "8px" }}>
				<label htmlFor="period-select" className="mb-0 flex-shrink-0">
					Periodo
				</label>
				<div className="position-relative flex-grow-1">
					<select
						id="period-select"
						className="form-select w-100"
						value={period}
						onChange={handleSelectChange}
						onClick={handleSelectClick}
						style={{ minHeight: "38px", fontSize: "1rem" }}
					>
						<option value={NONE}>Selecciona un periodo</option>
						<option value={PREVIOUS_DAY}>{renderPeriodOption(PREVIOUS_DAY)}</option>
						<option value={LAST_MINUTES}>
							{`Últimos minutos${minutes ? ` (${minutes})` : ""}`}
						</option>
						<option value={PREVIOUS_WEEK}>{renderPeriodOption(PREVIOUS_WEEK)}</option>

						<option value={PREVIOUS_MONTH}>{renderPeriodOption(PREVIOUS_MONTH)}</option>

						<option value={CUSTOM}>
							{`Personalizado${
								studyDateRange?.startDate && studyDateRange?.endDate && period === CUSTOM
									? ` (${formatDate(studyDateRange?.startDate)} - ${formatDate(
											studyDateRange?.endDate
									  )})`
									: ""
							}`}
						</option>
					</select>

					{/* Dropdown de minutos */}
					{period === LAST_MINUTES && isMinutesOpen && (
						<div
							ref={minutesRef}
							className="position-absolute mt-2 bg-white rounded shadow-lg w-100"
							style={{ zIndex: 999 }}
						>
							<select
								id="minutes-select"
								className="form-select w-100"
								value={minutes}
								onChange={handleMinutesChange}
								style={{
									minHeight: "38px",
									fontSize: "1rem",
									border: "none",
								}}
							>
								<option value={NONE_MINUTES}>Selecciona</option>
								{MINUTE_OPTIONS.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</select>
						</div>
					)}
				</div>
			</div>

			{isDateRangeOpen && (
				<div
					ref={dateRangeRef}
					className="position-absolute mt-2 bg-white rounded shadow-lg w-100 w-lg-auto start-0 start-lg-50 translate-lg-middle-x"
					style={{
						maxWidth: "100%",
						zIndex: 999,
					}}
					tabIndex={-1}
				>
					<DateRange
						rangeColors={["#f33e5b", "#3ecf8e", "#fed14c"]}
						months={1}
						direction="vertical"
						monthDisplayFormat="MMM yyyy"
						weekdayDisplayFormat="E"
						dayDisplayFormat="d"
						showMonthAndYearPickers={false}
						locale={localesByName[lang]}
						showDateDisplay={false}
						className="small overflow-hidden"
						ranges={dateRange}
						onChange={handleSelect}
					/>
				</div>
			)}
		</div>
	);
};
